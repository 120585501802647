import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, useIntl } from "gatsby-plugin-intl";
import Layout from "../layouts/main";
import SEO from "../components/seo";

const NotFoundPage = props => {
  const intl = useIntl();

  return (
    <Layout
      location={props.location}
      top={
        <SEO
          lang={intl.locale}
          title={`404: ${intl.formatMessage({ id: "title" })}`}
        />
      }
      center={
        <>
          <h1>
            <FormattedMessage id="notfound.header" />
          </h1>
          <p>
            <FormattedMessage id="notfound.description" />
          </p>
        </>
      }
    />
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.object,
};

export default injectIntl(NotFoundPage);
